<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/enroll-form.css";
</style>
<style type="text/css" scoped>
.modal-title /deep/ {
  text-align: center !important;
  width: 100% !important;
  padding: auto !important;
}

/deep/.el-select.blueBorder .el-input__inner {
  border-color: rgb(23, 118, 210);
  height: 30px;
}

/deep/.el-form-item {
  margin-bottom: 0;
}

/deep/.el-transfer-panel__list,
/deep/.el-transfer-panel__body {
  height: 400px;
}

.guding.top-guding {
  width: 30px;
}

@media screen and (max-width: 576px) {
  .modal-dialog {
    margin: 0 auto !important;
  }
}
</style>
<script>
import { chooseInfoMethods } from "@/state/helpers";

import Layout from "@/views/layouts/main";
import studentPrints from "@/components/print/studentPrints.vue";
import { getExamDetails } from "@/api/admin/exam/examRecord.js";
import {
  getRegisterUserList,
} from "@/api/admin/register/registerUser.js";
import { noChange } from "@/assets/js/nochange.js";
import $ from "jquery";
import {endLoading} from "@/libs/common";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    studentPrints,
  },
  data() {
    return {
      pdfList:[],
      title: "考试报名信息审核  / ",
      ksbmbh: "",
      items: [
        {
          text: "用户首页",
          href: "/admin",
        },
        {
          text: "报名审核",
          active: true,
        },
      ],

      examInfo: {},
      bmh: "",
      info: {},
      checkList: [],
      printList: [],
      isDialog: true,
      pageNum: 0,
      pageHeight: 1103,
      pdfHeight: 0,
      pdfNum: 1,
      row: 0,
      oldHeight: 0,
    };
  },
  methods: {
    ...chooseInfoMethods,

    afterRender() {
      console.log(123);
    },
    //获取考试详情
    getExamInfo() {
      getExamDetails(this.ksbmbh).then((res) => {
        if (res.status) {
          this.examInfo = res.data;
        }
      });
    },

    // 获取考生申请列表

    print() {},

    // 批量打印报名信息
    printAll(type) {
      this.pdfList = []
      let len = this.printList.length;
      let infoList=this.$refs.studentPrints
      let xm,sfzjh,info
      if (len) {
        let ids = [];
        this.printList.forEach((v) => {
          ids.push("pdf" + v + 1);
        });
        let message = "";
        let isPrint = true;
        if (type == "print") {
          isPrint = false;
          message = `是否打印所选考生报考信息,共${len}条数据?`;
        } else {
          message = `是否下载所选考生报考信息,共${len}条数据?`;
        }
        this.printList.forEach((v) => {
          ids.push("pdf" + v + 1);
          this.toPdfHtml("printId", "pdf" + v + 1, "pdf" + v);
        });

        this.$nextTick(() => {
          const pdfArr = [];
          const titleArr = [];
          this.printList.forEach((v) => {
            infoList.forEach((k) => {
              info=k.info
              if (info.bmh == v) {
                xm = info.xm
                sfzjh =info.sfzjh
              }
            })

            let title = "报名信息表-"+xm+"-"+sfzjh
            titleArr.push(title)
            pdfArr.push(this.ExportSavePdf2(title, "pdf" + v + 1, 0));

          });

          let _this = this
          Promise.all(pdfArr).then(function(values) {
            values.forEach((PDF,idx)=>{
              _this.pdfList.push({title: titleArr[idx], pdf: PDF})

            })

              // 打印或下载pdf
              _this.pdfList.forEach((v) => {
                let PDF = v.pdf
                if (isPrint === false) {
                  //预览
                  PDF.autoPrint();
                  window.open(PDF.output('bloburl'), '_blank');
                } else {
                  //下载
                  PDF.save(v.title + '.pdf')
                }
              })
              window.close()
          });
        });

      }
    },

    // 打印前的处理
    toPdfHtml(id, id1, id2) {
      let width = $("#" + id).outerWidth();
      this.pageHeight = Math.floor((width / 210) * 297);
      if ($("#" + id1).length > 0) {
        return false;
      }
      this.pdfNum = 1;
      this.pdfHeight = 0;
      $("#" + id).append("<div id='" + id1 + "'></div>");
      $("#" + id1).append(
        "<div class='pdf' id='" +
          id1 +
          "1' page='1' style='height:" +
          this.pageHeight +
          "px'></div>"
      );
      //
      if ($("#" + id2 + " .kstitle").length > 0) {
        this.addItem(
          $("#" + id2 + " .kstitle"),
          $("#" + id2 + " .kstitle").outerHeight(true),
          id1
        );
      }
      if ($("#" + id2 + " .jbxx").length > 0) {
        this.addItem(
          $("#" + id2 + " .jbxx .title"),
          $("#" + id2 + " .jbxx .title").outerHeight(true),
          id1
        );
        let itemhead = $("#" + id2 + " .jbxx .value").find(
          ".person-form.outerCotent>.msgContent>div:first-child"
        );
        this.addItem($(itemhead), $(itemhead).outerHeight(true), id1);
        let itemfoot = $("#" + id2 + " .jbxx .value").find(
          ".person-form.outerCotent>.msgContent>div:last-child"
        );
        $(itemfoot)
          .children()
          .children()
          .each((i, item) => {
            this.addItem(item, $(item).outerHeight(), id1);
          });
      }
      if ($("#" + id2 + " .bkxx").length > 0) {
        this.addItem(
          $("#" + id2 + " .bkxx "),
          $("#" + id2 + " .bkxx ").outerHeight(true),
          id1
        );
      }
      if ($("#" + id2 + " .msxx").length > 0) {
        this.addItem(
          $("#" + id2 + " .msxx"),
          $("#" + id2 + " .msxx").outerHeight(true),
          id1
        );
      }
      if ($("#" + id2 + " .bmzt").length > 0) {
        this.addItem(
          $("#" + id2 + " .bmzt"),
          $("#" + id2 + " .bmzt").outerHeight(true),
          id1
        );
      }
    },
    addItem(item, height, id, imgurl) {
      let paddingHeight = 60;
      height = Number(height);
      if (
        $(item).hasClass("msgLabel") &&
        !$(item).hasClass("w100") &&
        this.row == 0
      ) {
        this.row = 1;
      } else if (
        $(item).hasClass("msgLabel") &&
        !$(item).hasClass("w100") &&
        this.row != 0
      ) {
        this.oldHeight = height;
        this.row = 0;
        height = 0;
      }
      if (
        $(item).hasClass("msgLabel") &&
        $(item).hasClass("w100") &&
        this.row != 0
      ) {
        // this.pdfHeight += this.oldHeight;
        this.oldHeight=0
        this.row = 0;
      }

      // 当前pdf高度大于0，且未添加新对象高度前后的页数不一致则换页
      if (
        this.pdfHeight > 0 &&
        parseInt((this.pdfHeight + paddingHeight) / this.pageHeight) !=
          parseInt((this.pdfHeight + height + paddingHeight) / this.pageHeight)
      ) {
        // pdf页数加1
        this.pdfNum += 1;
        this.pdfHeight = (this.pdfNum - 1) * this.pageHeight;
        $("#" + id).append(
          "<div class='pdf' id='" +
            id +
            this.pdfNum +
            "' page='" +
            this.pdfNum +
            "'style='height:" +
            this.pageHeight +
            "px'></div>"
        );
      }


      this.pdfHeight += height;
      // 将内容放入div中
      if ($(item).hasClass("msgLabel")) {
        if ($("#" + id + this.pdfNum + " .flexBox").length) {
          $(item)
            .clone()
            .appendTo($("#" + id + this.pdfNum + " .flexBox"));
        } else {
          $("#" + id + this.pdfNum).append(
            "<div class='d-flex flex-wrap flexBox'></div>"
          );
          $(item)
            .clone()
            .appendTo($("#" + id + this.pdfNum + " .flexBox"));
        }
      } else {
        $(item)
          .clone()
          .appendTo($("#" + id + this.pdfNum));
      }
      $("#" + id + " .pdf").css("padding", "30px 25px");
      $("#" + id + " .pdf").css("color", "black");
      if (imgurl) {
        $("#" + id + " .pdf").css("background-image", "url(" + imgurl + ")");
      }
    },
    back(){
      this.$router.back()
    },
    printPdf() {
        let query = this.$route.query;
        setTimeout(()=> {
          this.printAll(query.type)
        }, 4000)

}
  },
  mounted() {},
  created() {
    let query = this.$route.query;
    this.ksbmbh = query.ksbmbh;
    this.printList = query.bmh.split("_");
    this.getExamInfo();



  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 650px">
          <div class="card-body">
            <div>

<!--              <button @click="back()" class="btn btn-sm btn-danger ml-3">-->
<!--                返回-->
<!--              </button>-->
            </div>
            <div class="container forbiddenText" >
              <div id="pdf">
                <div v-for="x in printList" :key="x.index" :id="'pdf' + x">
                  <studentPrints
                    :ksbmbh="ksbmbh"
                    :examInfo="examInfo"
                    :bmh="x"
                    checkType=""
                    @afterRender="afterRender"
                    @printPDF="printPdf"
                    ref="studentPrints"
                  />
                </div>
              </div>
              <div id="printId" style="width: 970px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
